import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { IconContext } from "react-icons"
import './Navbar.css';
import { FaBars, FaTimes} from "react-icons/fa"


function NavBar() {
    const [click, setClick] = useState(false);
 
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
         
   
 return (
  <>

   <IconContext.Provider value={{ color: "#141414"}}>
         <nav class="main-nav white stick-fixed js-transparent transparent">
   <nav className="navbar">
    <div className="navbar-container">

     <div className='menu-icon' onClick={handleClick}>
      {click ? <FaTimes /> : <FaBars />}
     </div>  
     <ul className={click ? 'nav-menu active' : 'nav-menu'}>

     </ul>
    </div>
  </nav>
  </nav>
  </IconContext.Provider>
  </>
    );
    
}

export default NavBar;