import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import './App.css';
import resume from '../pdf/resume.pdf';

const Resume = () => {
  const [numPages, setNumPages] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  
  // Update width on window resize
  window.addEventListener('resize', () => {
    setWidth(window.innerWidth);
  });

  // Callback when the document is successfully loaded
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  
  return (
    <div className="main-container">
      <div className="pdf-viewer">
        <Document 
          file={resume}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {/* Render each page dynamically */}
          {Array.from(new Array(numPages), (el, index) => (
            <Page 
              key={`page_${index + 1}`} 
              pageNumber={index + 1} 
              width={width > 768 ? 600 : width - 40} 
            />
          ))}
        </Document>
      </div>

      {/* Download Button */}
      <div className="download-button-container">
        <a href={resume} download="Scott_Hutson_Resume.pdf" className="download-btn">
          Download My Resume
        </a>
      </div>
    </div>
  );
};

export default Resume;