import React from 'react'
import './App.css';

export default () => (
  <div id="about">
  <img class="Family" src="family.jpg" alt="Card image" />
    <div class="card"> <div class="card-body"> After over ten years in the accounting industry I have decided to pursue a career in software development. 
      My experience stems from the mentorship of an established Senior Software Engineer, real world projects, a combination of Udemy classes, working towards an associates in Information Systems a Liberty University as well as a contract with Adviserly as a Front-End Developer. I am looking to secure full-time employment on a software development team,
     I'm also happy to connect with other developers so please feel free to add me to your professional LinkedIn. </div>
      </div>
      <p3></p3>
  </div>
);
